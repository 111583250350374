// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("C:\\Users\\Renddi\\re-layout\\src\\i18n\\redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Renddi\\re-layout\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Renddi\\re-layout\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Renddi\\re-layout\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\Renddi\\re-layout\\.cache\\data.json")

