import { createGlobalStyle } from 'styled-components';

import theme from './theme';

export default createGlobalStyle`
  * {
    -webkit-text-size-adjust: none;
  }

  @import url('https://fonts.googleapis.com/css?family=Barlow:400,600&display=swap');
  body {
    font-family: ${theme.font};
    min-width: 100%;
    min-height: 100%;
  }

  /* Use CSS Paged Media to switch from continuous documents to sheet-like documents with separate pages */
  @page {
    /* You can only change the size, margins, orphans, widows and page breaks here */

    /* Require that at least this many lines of a paragraph must be left at the bottom of a page */
    orphans: 4;
    /* Require that at least this many lines of a paragraph must be left at the top of a new page */
    widows: 2;

    margin: 0;
  }

  /* When the document is actually printed */
  @media print {
    html, body {
      /* Reset the document's background color */
      background-color: #fff;
    }

    header,
    .no-print {
      display: none !important;
    }

    .main-container {
      padding: 0 !important;
      height: auto !important;
    }

    .paper {
      /* Reset all page styles that have been for better screen appearance only */
      /* Break cascading by using the !important rule */
      /* These resets are absolute must-haves for the print styles and the specificity may be higher elsewhere */
      width: initial !important;
      min-height: initial !important;
      margin: 0 !important;
      padding: 0 !important;
      border: initial !important;
      border-radius: initial !important;
      ${'' /* background: initial !important; */}
      box-shadow: initial !important;

      /* Force page breaks after each .page element of the document */
      ${'' /* page-break-after: always; */}
    }
  }
`;
