import React from 'react';
import styled from 'styled-components';
import tag from 'clean-tag';
import {
  space,
  layout,
  typography,
  position,
  color,
  borderRadius,
  zIndex,
  border,
  borderColor,
  flex,
  opacity,
  style,
} from 'styled-system';

import blacklist from './utils/blacklist';
import injectProps from './utils/injectProps';
import styledToCss from './utils/styledToCss';

const beforeAfter = ({ children, ...props }) => {
  if (!children) return '';
  return ['before', 'after'].reduce((s, key) => {
    const config = props[key];
    if (config) {
      const { content, ...rest } = config;
      return `${s}
        &::${key}{
          content: ${content || '""'};
          ${(styledToCss(rest))}
        }
      `;
    }
    return s;
  }, '');
}

const Box = styled(tag)`
  ${space}
  ${layout}
  ${position}
  ${typography}
  ${color}
  ${position}
  ${zIndex}
  ${border}
  ${borderColor}
  ${flex}
  ${borderRadius}
  ${opacity}
  ${injectProps('whiteSpace')}
  ${injectProps('overflow')}
  ${injectProps('transform')}
  ${injectProps('transition')}
  ${injectProps('pointerEvents')}
  ${injectProps('textTransform')}
  ${injectProps('counterReset')}
  ${injectProps('counterIncrement')}
  ${style({
    prop: 'zOrder',
    cssProperty: 'zIndex',
    key: 'zOrder',
  })}
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${beforeAfter}
`;

Box.defaultProps = {
  blacklist,
};

Box.displayName = 'Box';

Box.inline = (props) => <Box is="span" display="inline-block" verticalAlign="middle" {...props} />;

export default Box;
